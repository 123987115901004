@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat|Inconsolata");

html {
  font-size: 14px;
  @media (min-width: 768px) {
    font-size: 16px;
  }
  scroll-behavior: smooth;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New";
}

div.fawesome-icon i {
  display: inline-block;
  width: auto;
  height: 4rem;
  margin: 0.5rem;
}
div.fawesome-icon img {
  display: none;
}

.skillIcon {
  padding: 1rem;
  width: 5rem;
  min-width: 60px;
  height: 100%;
}

.LazyLoad {
  opacity: 0;
  transition: all 1.5s ease-in;
}

.is-visible {
  opacity: 1;
}

.image {
  opacity: 0;
  transition: opacity 0.3s;
}
.image-loaded {
  opacity: 1;
}

.hero {
  text-shadow: 1px 1px 4px black;
}

.hero a {
  text-shadow: none;
}

.bgLayer {
  box-shadow: inset 0 0 0 1000px rgba(111, 108, 108, 0.3);
}

.bgstyle {
  -webkit-animation: BackgroundAnimation 70s ease infinite;
  -moz-animation: BackgroundAnimation 70s ease infinite;
  -o-animation: BackgroundAnimation 70s ease infinite;
  height: 100%;
  animation: BackgroundAnimation 70s ease infinite;
  background-size: cover;
}

.bg-gradient {
  background: linear-gradient(rgba(245, 245, 245, 1), rgba(245, 245, 245, 0));
}

.navbar-transparent {
  transition: background-color 0.2s;
  background-color: rgba(0, 0, 0, 0.1);
  text-shadow: -2px 2px 3px black;
}

.navbar-white {
  transition: background-color 0.2s;
  background-color: rgba(234, 236, 239, 0.81);
}

.nav-item {
  transition: color, 0.2s;
  color: #fff;
  &:hover{
    color: #62E884;
  }
}

.toggler {
  padding: 4px;
  border: none;
  border-radius: 4px;
  &:focus {
    outline: none;
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.social {
  padding-inline: 1rem;
  display: inline-block;
  position: relative;
  width: 5rem;
  height: 100%;
}

.social .img-top {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  display: inline;
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  opacity: 0;
  width: 5rem;
  height: 100%;
  transition: all 0.4s ease;
}

.title {
  left: 0;
  right: 0;
  height: 100%;
  margin-top: 0px;
  color: #fff;
  text-align: center;
}

.typist {
  min-height: 6rem;
  @media (min-width: 360px) {
    min-height: 4rem;
  }
  @media (min-width: 768px) {
    min-height: 2.5rem;
  }
}

.card {
  transition: transform 0.3s, box-shadow 0.3s;
  &:hover {
    box-shadow: 0 2rem 3rem rgba($black, 0.175) !important;
    transform: translateY(-2px);
    transition: transform 0.3s, box-shadow 0.3s;
  }
}

.skills-tabs {
  flex-grow: 1;
}

.skills-tab {
  flex-grow: 1;
  text-align: center;
  color: rgba(245, 245, 245, 1);
  &:hover {
    color: rgba(245, 245, 245, 1);
  }
}

.progress {
  transform-origin: left;
  & > * {
    transform: scaleX(0);
  }
}

.progress-bar-animation {
  & > * {
    animation: progress 1.5s ease-in-out forwards;
  }
}

.easeload {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  opacity: 1;
}

.hand {
  transform-origin: 70% 70%;
  display: inline-block;
  animation-name: wave;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
}

@media (hover) {
  .social:hover .img-top {
    opacity: 1;
  }
}

@keyframes wave {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  }
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
